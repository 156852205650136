<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      :header-bg-variant="headerModal"
      hide-footer
    >
      <h4>
        {{ title }}
      </h4>

      <validation-observer
        ref="transactionRules"
      >
        <div class="col-md-12 mt-1">
          <validation-provider
            #default="{ errors }"
            name="member"
            rules="required"
          >
            <label>ชื่อผู้ใช้</label>
            <b-input-group>
              <b-form-input
                v-model="search"
                @keyup="searchAccount"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  style="height: 2.714rem;cursor:pointer; border-color:#78AD83"
                >
                  <feather-icon
                    icon="SearchIcon"
                    style="width: 18px;"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="errors.length > 0"
              class="text-danger"
            >กรุณาเลือกสมาชิก</small>
          </validation-provider>
          <div
            v-show="ShowList"
            class="card w-100"
            style="position: absolute;
    z-index: 2;top:60px"
          >
            <div
              class="card-body shadow-item"
              style="padding: 0rem;"
            >
              <div
                v-for="(data , index) in listSearch"
                :key="index=index"
                class="row onHover"
                style="cursor:pointer"
                @click="onChangeAccount(data.username)"
              >
                <div class="col-12">
                  <div
                    id="setcolor"
                    style=" padding: 0.5rem; "
                  >
                    <span class="m-1">{{ data.username }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <validation-provider
          #default="{ errors }"
          name="credit"
          rules="numeric"
        >
          <div class="col-md-12 mt-1">
            <label>เครดิต</label>
            <b-form-input
              v-model="credit"
              type="number"
              class="form-control"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณาใส่จำนวนเครดิต</small>
        </validation-provider>
      </validation-observer>
      <div class="row">
        <div class="col-xl-12 text-center">
          <b-button
            variant="outline-secondary m-1"
            @click="onChangeCredit(100)"
          >
            +100
          </b-button>
          <b-button
            variant="outline-secondary m-1"
            @click="onChangeCredit(500)"
          >
            +500
          </b-button>
          <b-button
            variant="outline-secondary m-1"
            @click="onChangeCredit(1000)"
          >
            +1000
          </b-button>
        </div>
        <div class="col-xl-12">
          <label
            style="font-size: 15px"
            class="mt-1"
          >
            <font-awesome-icon
              :icon="['far', 'star']"
              style="width: 18px"
            />หมายเหตุ</label>
          <b-form-textarea
            id="textarea"
            v-model="detail"
            rows="3"
            max-rows="6"
          />
        </div>
      </div>
      <div class="row mt-2 d-flex justify-content-end">
        <b-button
          variant="danger"
          class="mr-1"
          @click="close"
        >
          ยกเลิก
        </b-button>
        <b-button
          v-if="title === 'เพิ่มเครดิต' || title === 'ถอนเครดิต' || title === 'ถอนเครดิตเกม'"
          variant="success"
          class="mr-1"
          :disabled="isConfirm"
          @click="onSubmit"
        >
          <b-spinner
            v-if="isConfirm"
            small
          />
          {{ btnConfirm }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BButton, BFormInput, BInputGroup, BInputGroupAppend, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, regex } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: '',
      openModal: false,
      accountTransaction: [{ text: '', value: '' }],
      member: '',
      credit: 0,
      title: '',
      required,
      numeric,
      regex,
      headerModal: '',
      search: '',
      listSearch: [],
      showList: [],
      btnConfirm: '',
      isConfirm: false,
      detail: '',
    }
  },
  computed: {
    ShowList() {
      const data = this.listSearch
      if (data.length > 0) {
        if (data.filter(x => x.username === this.search).length === 1) return false
      }
      return data.length > 0
    },
  },
  watch: {
    title() {
      if (this.title === 'เพิ่มเครดิต') {
        this.btnConfirm = 'เพิ่ม'
        this.headerModal = 'success'
      } else if (this.title === 'ถอนเครดิต') {
        this.btnConfirm = 'ถอน'
        this.headerModal = 'danger'
      } else if (this.title === 'ถอนเครดิตเกม') {
        this.btnConfirm = 'ถอน'
        this.headerModal = 'warning'
      }
    },
  },
  methods: {
    changeAccount(a) {
      console.log(a)
    },
    show(value) {
      this.title = value
      this.openModal = true
      this.defaultModal()
    },
    close() {
      this.openModal = false
    },
    onSubmit() {
      if (this.title === 'เพิ่มเครดิต') {
        this.isConfirm = true
        this.depositCredit()
      } else if (this.title === 'ถอนเครดิต') {
        this.isConfirm = true
        this.withdrawCash()
      } else if (this.title === 'ถอนเครดิตเกม') {
        this.isConfirm = true
        this.withdrawCreditGame()
      }
    },
    depositCredit() {
      if (this.credit <= 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ธุรกรรม',
            icon: 'DollarSignIcon',
            variant: 'danger',
            text: 'กรุณากรอกเครดิตให้มากกว่า 0',
          },
        })
        this.isConfirm = false
      } else {
        this.$refs.transactionRules.validate().then(success => {
          if (success) {
            const payload = {
              creditAmount: this.credit,
              transactionType: 'เพิ่มเครดิต',
              detail: 'เพิ่มเครดิต',
              username: this.search,
            }
            this.$store.dispatch('deposit/depositCash', payload).then(response => {
              if (response.data.success) {
              // console.log('success')
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('loadAllData')
                this.close()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
                this.isConfirm = false
              }
            })
          } else {
            this.isConfirm = false
          }
        })
      }
    },
    withdrawCash() {
      if (this.credit <= 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ธุรกรรม',
            icon: 'DollarSignIcon',
            variant: 'danger',
            text: 'กรุณากรอกเครดิตให้มากกว่า 0',
          },
        })
        this.isConfirm = false
      } else {
        this.$refs.transactionRules.validate().then(success => {
          if (success) {
            const payload = {
              creditAmount: this.credit,
              transactionType: 'ถอนเครดิต',
              detail: 'ถอนเครดิต',
              username: this.search,
            }
            this.$store.dispatch('withdraw/withdrawCash', payload).then(response => {
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('loadAllData')
                this.close()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
                this.isConfirm = false
              }
            })
          } else {
            this.isConfirm = false
          }
        })
      }
    },
    withdrawCreditGame() {
      if (this.credit <= 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ธุรกรรม',
            icon: 'DollarSignIcon',
            variant: 'danger',
            text: 'กรุณากรอกเครดิตให้มากกว่า 0',
          },
        })
        this.isConfirm = false
      } else {
        this.$refs.transactionRules.validate().then(success => {
          if (success) {
            const payload = {
              creditAmount: this.credit,
              transactionType: 'ถอนเครดิต',
              detail: 'ถอนเครดิต',
              username: this.search,
            }
            this.$store.dispatch('withdraw/withdrawCredit', payload).then(response => {
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('loadAllData')
                this.close()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ธุรกรรม',
                    icon: 'DollarSignIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
                this.isConfirm = false
              }
            })
          } else {
            this.isConfirm = false
          }
        })
      }
    },
    searchAccount() {
      this.$store.dispatch('account/searchAccount', { search: this.search }).then(res => {
        if (res.data.totalItem !== 0) {
          const newData = res.data.items.map(x => {
            const datamap = { username: x.username }
            return datamap
          })
          this.listSearch = newData
          console.log(res.data)
        } else {
          this.listSearch = []
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onChangeCredit(value) {
      this.credit += parseFloat(value)
    },
    onChangeAccount(value) {
      this.search = value
    },
    defaultModal() {
      this.credit = 0
      this.search = ''
      this.listSearch = []
      this.isConfirm = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.onHover:hover #setcolor{
  background-color: #78AD83;
  color: white;
}
</style>
