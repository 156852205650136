<template lang="">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row mb-1">
          <div class="col-12 col-md-6 col-xl-6">
            <div class="row">
              <div class="col-12 col-md-6 col-xl-6">
                <label>เริ่มต้น</label>
                <date-picker
                  ref="updateDate"
                  :date="startdate"
                  :type="'start'"
                  :status="false"
                  @setDatePicker="onSetDatePicker"
                />
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <label>สิ้นสุด</label>
                <date-picker
                  :date="enddate"
                  :type="'end'"
                  :status="false"
                  @setDatePicker="onSetDatePicker"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-6 mt-2">
            <div class="row">
              <div class="col-12 col-md-4 col-xl-4">
                <b-button
                  :variant="outline[0]"
                  block
                  @click="onSetbetween(0)"
                >
                  วันนี้
                </b-button>
              </div>
              <div class="col-12 col-md-4 col-xl-4">
                <b-button
                  :variant="outline[1]"
                  block
                  @click="onSetbetween(7)"
                >
                  7 วันที่แล้ว
                </b-button>
              </div>
              <div class="col-12 col-md-4 col-xl-4">
                <b-button
                  :variant="outline[2]"
                  block
                  @click="onSetbetween(30)"
                >
                  30 วันที่แล้ว
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 col-12">
            <b-card
              header-tag="header"
              header-bg-variant="success"
              header-class="justify-content-center"
              body-bg-variant="dark"
              class="text-center card-shadow rounded"
            >
              <template #header>
                <div class="d-flex justify-content-center">
                  <h4 class="mb-0 text-white font-weight-bolder">
                    ฝากเครดิต
                  </h4>
                </div>
              </template>
              <p class="mt-3 text-total text-white">
                {{ deposit | toCurrencyBath }}
              </p>
            </b-card>
          </div>
          <div class="col-md-6 col-lg-6 col-12">
            <b-card
              header-tag="header"
              header-bg-variant="danger"
              body-bg-variant="dark"
              header-class="justify-content-center"
              class="text-center card-shadow rounded"
            >
              <template #header>
                <div class="d-flex justify-content-center">
                  <h4 class="mb-0 text-white font-weight-bolder">
                    ถอนเครดิต
                  </h4>
                </div>
              </template>
              <p class="mt-3 text-total text-white">
                {{ withdraw | toCurrencyBath }}
              </p>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-3">
          <h3>
            จัดการธุรกรรม
          </h3>
          <div class="separator" />
        </div>
        <!-- end row card -->
        <div class="row mb-2">
          <div class="col-md-6 col-lg-6 col-12 mb-1">
            <b-button
              variant="success"
              size="lg"
              class="w-100"
              @click="openModal('เพิ่มเครดิต')"
            >
              เพิ่มเครดิต
            </b-button>
          </div>
          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <b-button
              variant="danger"
              size="lg"
              class="w-100"
              @click="openModal('ถอนเครดิต')"
            >
              ถอนเครดิต
            </b-button>
          </div>
          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <b-button
              variant="warning"
              size="lg"
              class="w-100"
              @click="openModal('ถอนเครดิตเกม')"
            >
              ถอนเครดิตเกม
            </b-button>
          </div>
        </div>
        <h3>รอดำเนินการ</h3>
        <list-waiting
          ref="listwaiting"
          @loadAllData="loadAllData"
        />
        <hr>
        <h3>เพิ่มเครดิต & ถอนเครดิต</h3>
        <list-all-process ref="listallprocess" />
      </div>
    </div>
    <modal-transaction
      ref="transactionmodal"
      @loadAllData="loadAllData"
    />
  </div>
</template>
<script>
import {
  BButton, BCard,
} from 'bootstrap-vue'
import moment from 'moment'
import ListWaiting from './listWaiting.vue'
import ListAllProcess from './listAllProcess.vue'
import ModalTransaction from './modalTransaction.vue'
import DatePicker from '../component/datePicker.vue'

export default {
  components: {
    BButton,
    BCard,
    ListWaiting,
    ListAllProcess,
    ModalTransaction,
    DatePicker,
  },
  data() {
    return {
      startdate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      enddate: moment().format('YYYY-MM-DD'),
      deposit: 0,
      withdraw: 0,
      type: '',
      outline: ['outline-primary', 'primary', 'outline-primary'],
    }
  },
  watch: {
    startdate() {
      this.loadData()
    },
    enddate() {
      this.loadData()
    },
    type(val) {
      console.log(val)
      for (let i = 0; i < 3; i += 1) {
        this.outline[i] = 'outline-primary'
      }
      if (val === 0) {
        this.outline[0] = 'primary'
      } else if (val === 7) {
        this.outline[1] = 'primary'
      } else if (val === 30) {
        this.outline[2] = 'primary'
      }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadAllData() {
      this.loadData()
      this.$refs.listwaiting.LoadList()
      this.$refs.listallprocess.LoadList()
    },
    openModal(value) {
      this.$refs.transactionmodal.show(value)
    },
    onSetDatePicker(data) {
      if (data.type === 'start') { this.startdate = moment(data.val).format('YYYY-MM-DD') } else this.enddate = moment(data.val).format('YYYY-MM-DD')
    },
    onSetbetween(val) {
      if (val === 0) {
        this.startdate = moment().format('YYYY-MM-DD')
        this.enddate = moment().format('YYYY-MM-DD')
      } else {
        this.startdate = moment().subtract(val, 'days').format('YYYY-MM-DD')
      }
      this.type = val
      this.$refs.updateDate.setdate(this.startdate)
    },
    loadData() {
      this.$store.dispatch('transaction/getSumCard', { startDate: this.startdate, endDate: this.enddate }).then(result => {
        if (result.data.success) {
          // eslint-disable-next-line no-underscore-dangle
          this.deposit = result.data.totalAll.find(x => x._id === 'เพิ่มเครดิต').total
          // eslint-disable-next-line no-underscore-dangle
          this.withdraw = result.data.totalAll.find(x => x._id === 'ถอนเครดิต').total
        }
      }).catch(error => {
        console.log('test', error)
      })
    },
  },
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.text-total {
    font-size:3rem;
    font-weight: bolder;
}
</style>
