<template lang="">
  <div>
    <div class="custom-search d-flex align-items-center justify-content-end mb-1">
      <b-form-group style="margin-bottom: 0rem;">
        <div class="d-flex align-items-center">
          <label class="mr-1">ค้นหา</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="ค้นหา"
            type="text"
            class="d-inline-block mr-1"
          />
        </div>
      </b-form-group>
    </div>
    <b-table
      striped
      hover
      responsive
      class="type-relative"
      :per-page="options.perPage"
      :current-page="options.currentPage"
      :items="listWaiting"
      :fields="fields"
      :filter="searchTerm"
      show-empty
      @filtered="onFiltered"
    >
      <template #cell(transactionType)="data">
        <b-badge
          v-if="data.item.transactionType === 'เพิ่มเครดิต'"
          variant="success"
        >
          {{ data.item.transactionType }}
        </b-badge>
        <b-badge
          v-if="data.item.transactionType === 'ถอนเครดิต'"
          variant="danger"
        >
          {{ data.item.transactionType }}
        </b-badge>
      </template>
      <template #cell(fulltime)="data">
        {{ data.item.created_at | toTimeThai }}
      </template>
      <template #cell(action)="data">
        <div class="d-grid d-md-block">
          <b-button
            class="mr-1"
            variant="success"
            size="sm"
            @click="confirmTransaction(data.item._id, data.item.userId)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            class="btn btn-danger mr-1"
            variant="danger"
            size="sm"
            @click="cancelTransaction(data.item._id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
      </template>
      <template #cell(creditAmount)="data">
        {{ data.item.creditAmount | toCurrencyBathTable }}
      </template>
      <!-- <template #cell(detail)="data">
        <b-badge
          v-if="data.item.detail.at(-1) === 'รอดำเนินการ'"
          variant="secondary"
        >{{ data.item.detail.at(-1) }}</b-badge>
        <b-badge
          v-if="data.item.detail.at(-1) === 'เกิดข้อผิดพลาด' || data.item.detail.at(-1) === 'ยกเลิกรายการ'"
          variant="danger"
        >{{ data.item.detail.at(-1) }}</b-badge>
        <b-badge
          v-if="data.item.detail.at(-1) === 'ดำเนินการเรียบร้อยแล้ว'"
          variant="success"
        >{{ data.item.detail.at(-1) }}</b-badge>
      </template> -->
      <template #empty>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลธุรกรรม
          </h4>
        </div>
      </template>
      <template #emptyfiltered>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลธุรกรรม
          </h4>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-if="totalItem > 0"
      class="d-flex justify-content-between flex-wrap pt-0 mt-2"
    >

      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="options.perPage"
          size="sm"
          inline
          :options="options.pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="options.currentPage"
          :total-rows="options.totalRows"
          :per-page="options.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <modal-confirm-waiting
      :id="id"
      ref="confirmwaiting"
      @loadData="loadData"
    />
  </div>
</template>
<script>
import {
  BCardBody, BPagination, BFormSelect, BTable, BFormGroup, BFormInput, BBadge, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalConfirmWaiting from './modalConfirmWaiting.vue'

export default {
  components: {
    BCardBody,
    BPagination,
    BFormSelect,
    BTable,
    BFormGroup,
    BFormInput,
    BBadge,
    BButton,
    ModalConfirmWaiting,
  },
  data() {
    return {
      id: '',
      listWaiting: [],
      searchTerm: '',
      options: {
        perPage: 10,
        currentPage: 1,
        pageOptions: [10, 25, 50, 100],
        totalRow: 10,
      },
      totalItem: 0,
      fields: [
        {
          key: 'transactionType',
          label: 'ประเภท',
          sortable: true,
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:100px',
        },
        {
          key: 'userId.username',
          label: 'ชื่อผู้ใช้',
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:150px',
        },
        {
          key: 'fulltime',
          label: 'วันที่และเวลา',
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:250px',
        },
        {
          key: 'creditAmount',
          label: 'เครดิต',
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:170px',
        },
        {
          key: 'detailBill',
          label: 'รายละเอียด',
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:200px',
        },
        {
          key: 'action',
          label: 'การกระทำ',
          thClass: 'bg-warning text-white',
          thStyle: 'min-width:200px',
        },
      ],
    }
  },
  computed: {
    checkEmptyTable() {
      return this.listWaiting.length > 0
    },
  },
  created() {
    this.LoadList()
  },
  methods: {
    loadData() {
      this.$emit('loadAllData')
    },
    async LoadList() {
      try {
        const result = await this.$store.dispatch('transaction/getWaitingTransaction')
        this.listWaiting = result.data.items
        this.totalItem = result.data.totalItem
        this.options.totalRows = result.data.totalItem
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    },
    onFiltered(FilterItem) {
      this.options.totalRow = FilterItem.length
      this.options.currentPage = 1
    },
    cancelTransaction(id) {
      if (id !== '' && id !== undefined) {
        this.$store
          .dispatch('transaction/updateStatus', { transactionId: id, status: 'Cancel' })
          .then(response => {
            if (response.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('loadAllData')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ลูกค้า',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: error.data.message,
              },
            })
          })
      }
    },
    async loadAllProcess() {
      await this.$store.dispatch('transaction/getAllTransaction')
    },
    confirmTransaction(id, userid) {
      console.log(userid)
      if (id !== '' && id !== undefined) {
        if (userid === '' || userid === null || userid.length === 0 || userid === undefined) {
          this.$refs.confirmwaiting.show()
          this.id = id
        } else {
          this.$store
            // eslint-disable-next-line no-underscore-dangle
            .dispatch('transaction/updateStatus', { transactionId: id, status: 'Confirm', userId: userid._id })
            .then(response => {
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('loadAllData')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: error.data.message,
                },
              })
            })
        }
      }
    },
  },
}
</script>
<style lang="">

</style>
